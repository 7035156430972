* {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
}


*,
::after,
::before {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

a {
  text-decoration: none;
  -webkit-transition: all 0.5s ease-in-out 0s;
  -o-transition: all 0.5s ease-in-out 0s;
  transition: all 0.5s ease-in-out 0s;
}

table a {
  color: #0d6efd !important;
  text-decoration: underline !important;
  cursor: pointer;
}

/* ============= home about section starts=============== */
.wrapper {
  position: relative;
  width: 100%;
  overflow: auto;
}





/* ---------------------------------------------------
    SIDEBAR STYLE start
----------------------------------------------------- */



#sidebar {
  position: fixed;
  height: 100vh !important;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 11;
  width: 260px;
  overflow: auto;
  transition: all 0.3s;
  background: #FFFFFF;
  box-shadow: 0 10px 30px -16px rgb(0 0 0 / 42%);
  /* cursor: pointer; */
}


#sidebar::-webkit-scrollbar {
  width: 5px;
  border-radius: 10px;
  background-color: #eee;
  display: none;
}

#sidebar::-webkit-scrollbar-thumbs {
  width: 5px;
  border-radius: 10px;
  background-color: #333;
  display: none;
}


#sidebar:hover::-webkit-scrollbar {
  display: block;
}

#sidebar:hover::-webkit-scrollbar-thumbs {
  display: block;
}

#sidebar .sidebar-header {
  padding: 10px;
  background-color: #ffffff;
  border-bottom: 1px solid #eee;
  text-align: center;
  cursor: pointer;
}

.active .sidebar-header {
  padding: 10px !important;
  text-align: center;
}

.sidebar-header h3 {
  color: #333;
  font-size: 17px;
  margin: 0px;
  text-transform: uppercase;
  transition: all 0.5s ease;
  font-weight: 600;
}

.sidebar-header h3 img {
  width: 45px;
  margin-right: 10px;
}

#sidebar ul li {
  padding: 2px 7px;
  cursor: pointer;
}

#sidebar ul li.active>a {
  color: #19C958;
  background-color: rgba(25, 201, 88, 22%);
  border-left-style: solid;
  border-left-color: #19C958;
  border-left-width: px;
  font-weight: 600;
}

#sidebar .active i {
  color: #19C958 !important;

}

#sidebar ul li a:hover {
  background-color: rgba(25, 201, 88, 15%);
}

#sidebar ul li a {
  color: #333;
}

.dropdown-toggle::after {
  position: absolute;
  right: 10px;
  top: 23px;
}

#sidebar ul li.drodown {
  position: sticky;
}


#sidebar ul.components {
  padding: 20px 0;
}

.active .components i {
  left: 50%;
  transform: translateX(-50%);
  margin: 0 !important;


}

#sidebar ul li a {
  padding: 8px;
  line-height: 30px;
  border-radius: 5px;
  font-size: 14px;
  position: relative;
  font-weight: 400;
  display: block;
}

#sidebar ul li a span {
  text-transform: capitalize;
  display: inline-block;
}

#sidebar ul li a i {
  position: relative;
  margin-right: 10px;
  color: #555555;
  margin-left: 10px;
  font-size: 16px;
}

.header-icon i {
  font-size: 20px;
  color: #9E9E9E;
}

/* ---------------------------------------------------
 sidebar end
----------------------------------------------------- */

/*=======================================================
   main-content navbar-design start
   ===============================================*/



#content {
  position: relative;
  transition: all 0.3s;
  background-color: #EEEEEE;
}

.top-navbar {
  width: 100%;
  z-index: 9;
  position: relative;
  box-shadow: 0 10px 10px -15px rgb(0 0 0 / 42%);
}

.main-content {
  padding: 20px;
  position: relative;
  width: 100%;
  /* height: 100vh; */
  background-color: #F8FAFA;
}

ul.nav.navbar-nav.ml-auto {
  align-items: center;
}

.navbar {
  background-color: #ffffff;
  color: #FFFFFF;
}

.navbar-brand {
  color: #FFFFFF;
}

.navbar button {
  background-color: transparent;
  border: none;
}

.navbar button span {
  color: #7c7272;
}

.nav-link:focus,
.nav-link:hover {
  color: #555555;
}

#sidebarCollapse {
  border-radius: 50%;
  width: 45px;
  height: 45px;
  text-align: center;
  line-height: 45px;
  /* margin-right: 20px; */
  border: none;
  color: #FFFFFF;
  background-color: rgba(0, 0, 0, 0.09);
}

#sidebarCollapse span {
  margin: 9px;
  padding: 0px;
}


.navbar-nav>li.active {
  color: #FFFFFF;
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.08);
}

a.nav-link {
  color: #263238;
}

.navbar .notification {
  position: absolute;
  color: #ffffff;
  top: 0;
  right: -2px;
  display: block;
  font-size: 9px;
  border: 0px;
  font-size: 10px;
  background: #d9534f;
  min-width: 18px;
  text-align: center;
  height: 18px;
  border-radius: 30px;
  line-height: 18px;
}


.navbar-nav>li.show .dropdown-menu {
  transform: translate3d(0, 0, 0);
  opacity: 1;
  visibility: visible;
}


.dropdown-menu li>a {
  font-size: 13px;
  padding: 10px 20px;
  margin: 0 5px;
  border-radius: 2px;
  font-weight: 400;
  transition: all 150ms linear;
}

.navbar-nav>.active>a:focus {
  color: #FFFFFF;
  background-color: rgba(0, 0, 0, 0.08);
}


a.mainbutton {
  background: #19C958;
  border-radius: 5px;
  font-size: 16px;
  color: #fff;
  padding: 8px 16px;
  cursor: pointer;
}

h4 {
  text-transform: uppercase;
  font-size: 18px;
  color: #06103c;
  margin-bottom: 20px;
}

.dashboard-sec .dash-outer-box {
  /* display: flex; */
  padding: 16px;
  background: #fff;
  margin-bottom: 12px;
  /* align-items: center;
  justify-content: center; */
  border-radius: 8px;
  box-shadow: 0 10px 10px -15px rgb(0 0 0 / 42%);
  /* cursor: pointer; */
}

.dash-outer-box h3 {
  font-size: 14px;
  text-transform: uppercase;
  color: #000000;
  margin-bottom: 10px;
}

section.dashboard-sec {
  margin-bottom: 18px;
}

.box-content h6 {
  font-size: 20px;
  font-weight: 800;
  margin: 0px;
  color: #2F3941;
  margin-bottom: 8px;
  font-family: roboto;
}

.box-content {
  /* padding-right: 50px; */
  align-items: center;
  justify-content: space-between;
  display: flex;
}

.icons {
  padding-top: 5px;
}

.dast-title p {
  margin-bottom: 0;
  font-size: 12px;
  vertical-align: baseline;
}

span.user {
  color: black;
  float: left;
  padding: 0px 8px;
}

.dash-icon {
  border-radius: 50px;
  width: 45px;
  height: 45px;
  align-items: center;
  justify-content: center;
  display: flex;
}

.dash-icon-color1 {
  background: #a4d3db;
  color: #0694A2;
}

.dash-icon-color2 {
  background: #cbdeff;
  color: #3F83F8;
}

.dash-icon-color3 {
  background: #ffbea6;
  color: #FF5A1F;
}

.dash-icon-color4 {
  background: #b5f5fc;
  color: #0694A2;
}

.dash-icon-color5 {
  background: #ffbfd0;
  color: #F41750;
}

.dash-icon-color6 {
  background: #d2ffc7;
  color: #23890B;
}

.dashboard-sec .custom-column {
  width: 20%;
}

.add-btn {
  background-color: #19C958;
  padding: 7px;
  margin-bottom: 15px;
  color: #ffffff;
  border-radius: 3px;
  cursor: pointer;
  font-size: 14px;
}

a.edit i {
  margin-right: 5px;
  font-size: 14px;
}

a.edit {
  color: #19C958 !important;
  text-decoration: none !important;
  border: 1px solid #19C958;
  padding: 4px 16px;
  border-radius: 3px;
  cursor: pointer;
}

a.edit:hover {
  color: #ffffff !important;
  border: 1px solid #ffffff !important;
  background-color: #19C958;
  border-radius: 5px;
  padding: 7px 16px;
}

.common-heading-sec {
  display: flex;
  align-items: center;

}

.common-heading-sec h5 {
  text-transform: uppercase;
  font-size: 18px;
}

.common-heading-sec .search-bar {
  margin-bottom: 15px;
  margin-right: 8px;

}

.common-heading-sec .search-bar-notify {
  margin-bottom: 15px;
  margin-right: 55px;

}

.form-control-notify {
  display: block;
  width: 120%;
  padding: 0.28rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.search-bar {
  margin-bottom: 12px;
}

.common-heading-sec .dropdown-sec {
  margin-bottom: 15px;
  margin-right: 10px;
}

.menus-icon {
  margin: 0 auto;
  width: 30px;
  height: 30px;
  border-radius: 30px;
  background-color: #dcdcdc;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-align: center;
}

.menus-icon i {
  font-size: 14px;
}

.custom-table td {
  border: none;
}

table td {
  vertical-align: middle;
}

.list-group-item {
  border: none;
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 26%);
  cursor: pointer;
  font-size: 14px;
}

.table {
  width: 100%;
  border-collapse: collapse;
  position: relative;
}

.table td,
.table th {
  font-size: 14px;
}

.good-color {
  color: #19C958;
  background-color: #d0ffe1a4;
  padding: 4px 16px;
  border-radius: 50px;
}

.per-block-color {
  color: #c91919;
  background-color: #ffd0d0;
  padding: 4px 16px;
  border-radius: 50px;
}

.pending-color {
  color: #c9a019;
  background-color: #fff2d0;
  padding: 4px 16px;
  border-radius: 50px;
}

.block-color {
  color: #1997c9;
  background-color: #d0f4ff;
  padding: 4px 16px;
  border-radius: 50px;
}

.review-color {
  color: #040D58;
  background-color: #283be442;
  padding: 4px 16px;
  border-radius: 50px;
}

span.premium-color {
  color: #19C958;
  background: rgb(201 248 217);
  padding: 4px 16px;
  border-radius: 50px;
  /* cursor: pointer; */
}

span.supreme-color {
  border-radius: 50px;
  color: #c2a031;
  background: rgb(255 246 195);
  padding: 4px 16px;
  /* cursor: pointer; */
}

span.none-color {
  border-radius: 50px;
  color: #d60000;
  background: rgb(255 217 217);
  padding: 4px 16px;
  /* cursor: pointer; */
}

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
  border: none;
}

.page-link {
  position: relative;
  display: block;
  color: #6c757d;
  text-decoration: none;
  background-color: #fff;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  border: none;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #19C958;
  border-radius: 5px;
}

.menu-popups {
  z-index: 9;
  max-width: 250px;
  position: absolute;
  right: 46px;
  top: 20px;
}

div#myDIV {
  display: none;
}

table.table tr {
  position: relative;
}




/*=======================================================
   new-customer
   ===============================================*/
/*responsive*/

@media(max-width: 500px) {
  .table thead {
    display: none;
  }

  .table,
  .table tbody,
  .table tr,
  .table td {
    display: block;
    width: 100%;
  }

  .table tr {
    margin-bottom: 15px;
  }

  .table td {
    text-align: right;
    padding-left: 50%;
    text-align: right;
    position: relative;
  }

  .table td::before {
    content: attr(class);
    position: absolute;
    left: 0;
    width: 50%;
    padding-left: 15px;
    font-size: 14px;
    font-weight: bold;
    text-align: left;
  }
}

a.view {
  color: #db0808;
  border: 1px solid #dd0000;
  padding: 2px 10px;
  border-radius: 5px;
}

.pagination {
  justify-content: center;
}

/* ---------------------------------------------------
    MEDIAQUERIES
----------------------------------------------------- */

@media only screen and (min-width:720px) {
  #sidebar.active {
    width: 80px;
    height: 100% !important;
    position: absolute !important;
    /* overflow: visible !important; */
    top: 0 !important;
    z-index: 666;
    float: left !important;
    bottom: 0 !important;
  }


  #sidebar.active .sidebar-header h3 span {
    display: none;
    transition: all 0.5s ease;
  }

  #sidebar.active ul li a span {
    display: none;
    transition: all 0.5s ease;
  }

  #sidebar.active .dropdown-toggle::after {
    display: none;
    transition: all 0.5s ease;
  }

  #content {
    width: calc(100% - 260px);
    position: relative;
    float: right;
    transition: all 0.3s;
    background-color: #EEEEEE;
  }

  #content.active {
    width: calc(100% - 80px);
  }

  #sidebar.active .menu {
    position: absolute;
    left: 81px;
    background-color: white;
    width: 180px;
    height: auto;
    margin: 5px 0;
    top: 0;
    border: 1px solid #dcd9d9;
    z-index: 4;
  }
}


@media only screen and (min-width:720px) {
  .dropdown-menu {
    border: 0;
    box-shadow: 0 2px 5px 0 rgb(0 0 0 / 26%);
    transform: translate3d(0, -20px, 0);
    visibility: hidden;
    transition: all 150ms linear;
    display: block;
    min-width: 15rem;
    right: 0;
    left: auto;
    opacity: 0;
  }

}

.navbar-expand-lg .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
  justify-content: flex-end;
  align-items: center;
}

.header-icon select {
  border: 0;
  cursor: pointer;
}





/*===============small-screen overlay sidebar design media queries==============*/

@media only screen and (max-width:720px) {
  #sidebar {
    position: fixed;
    top: 0;
    bottom: 0;
    z-index: 10;
    width: 260px;
    transform: translatex(-100%);
    transition: all 150ms linear;
    box-shadow: none !important;
  }

  .body-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: none;
    visibility: hidden;
    opacity: 0;
    z-index: 3;
    transition: all 150ms linear;
    background-color: rgba(0, 0, 0, 0.5);
  }
}

.active .img-fluid {

  display: none;
}

.img-simpl {

  display: none;
  transition: 1s;
  max-width: 100%;

}

.active .img-simpl {
  display: revert;

}

/*===============small-screen overlay sidebar design media queries==============*/


/* Login page */
.rigt-side-img {
  width: 100%;
  height: 100vh;
  object-fit: cover;
}

.login-right-sec {
  position: relative;
}

.login-right-sec::after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.12);
  object-fit: cover;
}

.rigt-side-logo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100px;
  height: 100px;
  z-index: 1;
}

.login-Left-sec {
  padding: 60px 110px;

}

.login-page h1 {
  text-transform: uppercase;
  color: #263238;
  margin-bottom: 40px;
  font-size: 36px;

}

.btn-login {
  width: 100%;
  background-color: #19C958;
  border: none;
  color: #ffffff !important;
  transition: 1s;
  padding: 0.7rem 0.75rem;
  text-decoration: none !important;
}

.btn-login:hover {
  width: 100%;
  background-color: #24aa53;
  color: #ffffff;
  border: none;
}

.login-page .form-control {
  padding: 0.7rem 0.75rem;
  border: 1px solid rgb(206 212 218 / 38%);
}

.loginpage-logo {
  margin-bottom: 30px;
}

.login-bottom-link {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
}

.login-page .form-control::-webkit-input-placeholder {
  color: #CFCFCF;
}

.login-page .form-label {
  color: #263238;
}

.login-page a {
  color: #19C958;
  text-decoration: underline;
}

.modal-body {
  padding: 1.5rem;
}

.caption-center {
  text-align: center;
}

.modal-title {
  font-size: 18px;
}

.modal-header {
  justify-content: center;
  padding: 10px;
}

.modal-footer {
  justify-content: center;
  border-top: 0px;
}

button.btn.form-submit-secbtn {
  border: 1px solid;
  color: #dc3545;
  border-radius: 5px;
  font-size: 14px;
}

button.btn.form-submit-btn {
  color: #fff;
  background: #19C958;
  border-radius: 5px;
  font-size: 14px;
}

button.btn.form-delete-btn {
  color: #fff;
  background: #dc3545 !important;
  border-radius: 5px;
  font-size: 14px;
}

button.btn.form-submit-editbtn {
  background: rgb(103, 104, 122);
  color: #fff;
  padding: 6px 22px;
  border-radius: 5px;
  font-size: 14px;
}

.edit {
  padding: 10px;

}

.active-color {
  color: #19C958;
  background: #c1ffd7;
  border-radius: 50px;
  padding: 4px 16px;
  /* cursor: pointer; */
}

.inactive-color {
  color: #d60000;
  background: rgb(255 217 217);
  border-radius: 50px;
  padding: 4px 16px;
  /* cursor: pointer; */
}

.slider-container {
  position: relative;
}

.slider-container .bar {
  position: absolute;
  z-index: 1;
  left: 0px;
  top: 10px;
  width: 100%;
  height: 6px;
  border-radius: 5px;
  background-color: #aee7bf;
  overflow: hidden;
}

.slider-container .bar .fill {
  display: block;
  width: 0;
  height: 100%;
  background-color: #19C958
}

.slider-container .slider {
  position: relative;
  z-index: 2;
  -webkit-appearance: none;
  width: 100%;
  height: 10px;
  border-radius: 5px;
  outline: none;
  background-color: transparent;
}

.slider-container .slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 30px;
  height: 30px;
  background-color: #19c958;
  border-radius: 50%;
  cursor: pointer;
  outline: none;
  transition: 0.3s ease-in-out;
}

.controlls {
  width: 75%;
  margin-top: 2.5em;
}

.progress_part {
  width: 100%;
  margin-bottom: 2em;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}

.progress_part p {
  color: #fff;
}

.progress_part .durations {
  width: 100%;
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
}

.progress_part #slider {
  width: 100%;
}

.controlls_btns {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0px;
  background: rgb(226, 226, 226);
  border: 1px solid #d1d1d1;
}

.controlls_btns button {
  height: 50px;
  width: 50px;
  border: none;
  outline: none;
  cursor: pointer;
  margin: 0 0.5em;
  background: none;
  transition: 0.5s;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  display: flex;
  font-size: 14px;
}

button#play_pause_btn {
  background: #6d6d6d;
  width: 45px;
  height: 45px;
  align-items: center;
  justify-content: center;
}

button#play_pause_btn i {
  color: #ffffff;
}

.controlls_btns button i {
  font-size: 1.3em;
}

.controlls_btns button i {
  color: rgb(5, 5, 5);
}

.required:after {
  content: " *";
  color: #FB1718;
}

/* tags page start */
.tag-box {
  border: 1px solid #d0d0d0;
  padding: 15px;
  border-radius: 5px;
}

.tag-box .tag-outer-box {
  background-color: #808082;
  color: #ffffff;
  border-radius: 50px;
  margin: 0 5px 14px 0;
  padding: 5px 10px;
  line-height: 1;
  display: inline-block;
  text-decoration: none;
  font-size: 14px;
  transition: 0.5s;
}


.icon-rount {
  background: #ffffff;
  color: #808082;
  width: 18px;
  height: 18px;
  border-radius: 50px;
  align-items: center;
  justify-content: center;
  display: inline-flex;
  margin-left: 4px;
  font-size: 12px
}

.col-lg-2.d-flex.align-items-end {
  padding: 4px;
}

/* tags page end */
.custom-toggle {
  display: flex;
  /* vertical-align: middle; */
  align-items: center;
}

.custom-toggle input#flexSwitchCheckChecked {
  margin-top: 0;
}

.cate-outer-box {
  display: inline-block;
  margin: 0 12px 12px 0;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 6px;
}

/* categeries page start */
input,
input::-webkit-input-placeholder {
  font-size: 14px;
  line-height: 3;
}

label {
  font-size: 14px !important;
}

.cate-inner-box p {
  margin-bottom: 0 !important;
}

.categeries-box .cate-inner-box {
  background-color: #808082;
  color: #ffffff;
  border-radius: 3px;
  padding: 5px;
  line-height: 1;
  display: flex;
  align-items: center;
  text-decoration: none;
  font-size: 14px;
  transition: 0.5s;
  margin-bottom: 5px;

}

.categeries-box .cate-inner-box img {
  margin-right: 5px;
  border-radius: 60px;
  width: 25px;
  height: 25px;

}

.cate-outer-box p {
  margin: 0;
  font-size: 13px;
  margin-bottom: 3px;
}

.icon-box {
  background: #ffffff;
  color: #808082;
  width: 20px;
  height: 20px;
  border-radius: 50px;
  align-items: center;
  justify-content: center;
  display: inline-flex;
  margin-left: 4px;
  font-size: 12px;
  cursor: pointer;

}

.category-tabs .nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  font-size: 16px;
  color: #19C958;
  background-color: #0d6efd00;
  font-weight: 500;
  padding-bottom: 2px;
  border-bottom-style: solid;
  border-bottom-width: 4px;
  border-bottom-color: #19C958;
  width: fit-content;
}

.category-tabs .nav-link {
  margin: 0.5rem 1rem !important;
  padding: 0;
}

.category-tabs .nav-pills .nav-link {
  font-size: 16px;
  color: #555555;
}

/* categeries page end*/

.bg-white {
  background-color: #fff !important;
  /* height: 450px; */
  padding: 20px;
}

.form-switch .form-check-input {
  width: 2.3em;
  height: 1.4em;
  cursor: pointer;
}

.form-check-input:checked {
  background-color: #19c958;
  border-color: #19c958;
}

.searchAdd-fields {
  display: flex;
  justify-content: end;
}

td.story-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 130px;
}

.audio-time {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.check-box {
  display: flex;
  align-items: center;
}

.form-select {
  font-size: 14px;
  cursor: pointer;
}

.form-control {
  font-size: 14px;
}


.alert_box .icon {
  height: 72px;
  width: 72px;
  color: #f23b26;
  border: 3px solid #f23b26;
  border-radius: 50%;
  line-height: 97px;
  font-size: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto
}

.alert_box header {
  font-size: 25px;
  font-weight: 500;
  margin: 10px 0;
  text-align: center;
}

.alert_box p {
  font-size: 16px;
  text-align: center;
}

.alert_box .btns {
  margin-top: 20px;
}

.form-check-input {
  width: 1.2em;
  height: 1.2em;
}

.custom-toggle .form-check {
  margin-bottom: 0;
  min-height: 0;
  margin-left: 4px;
}

.drag-area {
  border: 2px dashed rgb(185, 185, 185);
  width: 100%;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 10px;
}

.drag-area.active {
  border: 2px solid rgb(0, 0, 0);
}

.drag-area .icon {
  font-size: 42px;
  color: #cfcfcf;
}

.drag-area .icon-edit {
  font-size: 42px;
  color: #cfcfcf;
  width: 150px;
  height: 150px;
}

.drag-area header {
  font-size: 15px;
  font-weight: 500;
  color: rgb(0, 0, 0);
}

.drag-area span {
  font-size: 15px;
  font-weight: 500;
  color: rgb(0, 0, 0);
  margin: 10px 0 10px 0;
}

.drag-area button {
  padding: 8px;
  font-size: 14px;
  font-weight: 500;
  border: none;
  outline: none;
  background: #999999;
  color: #ffffff;
  border-radius: 5px;
  cursor: pointer;
}

.drag-area img {
  height: 100%;
  width: 100%;
  object-fit: contain;
  border-radius: 5px;
}

.form-input-error {
  color: #dc3545;
}

.msl-wrp.msl-vars.undefined {
  width: 100%;
}

.msl {
  display: block;
  width: 100%;
  /* padding: 0.375rem 0.75rem; */
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.msl-input[data-placeholder]:not([data-placeholder=""]):empty:before {
  content: attr(data-placeholder);
  font-size: 14px;
}

.msl-input {
  margin: 2.3px;

}

.nodata-found {
  font-size: 18px;
  font-weight: 700;
  /* color: #0d6efd; */
  color: #d60000;
  margin-bottom: 0;
}

.msl-options {
  height: 200px;
}

.drag-area {
  position: relative;
}

input[type="file"] {
  font-size: 5px;
  line-height: 20px;
  padding-left: 5px;
  color: #bd062c;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.categoryCenter {
  text-align: center;
}

audio#audioval {
  width: 100%;
}

.user-upload-btn-wrapper img {
  width: 140px;
  height: 140px;
  border: #ddd solid 1px;
  border-radius: 5%;
}

.user-upload-btn-wrapper {
  text-align: center;
}

.popshowclim {
  position: absolute;
  inset: 0px 0px auto auto;
  margin: 0px;
  transform: translate(-99.625px, -125px);
}

.alert_box .icon {
  height: 72px;
  width: 72px;
  color: #19C958;
  border: 3px solid #19C958;
  border-radius: 50%;
  line-height: 97px;
  font-size: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.add-language span {
  color: #f00;
  margin-left: 3px;
}

.pointer span {
  cursor: pointer;
}

/* .msl-btn.msl-clear-btn.msl-flx.clear_items {
  display: none;
} */
.cate-inner-box p {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  width: 80%;
  cursor: pointer;
}

.cate-outer-box.clo-md-8 {
  flex: 0 0 auto;
  width: 15.6%;
}

.drop_clim {
  width: 220px;
}

.wid-th {
  width: 230px;
}

.btn-group-sm>.btn, .btn-sm {
  padding: 0.1rem 0.5rem;
}