@media only screen and (max-width: 1399px) {
    #sidebar.active {
        width: 80px;
        height: 100vh !important;
        position: fixed !important;
        /* overflow: visible !important; */
        top: 0 !important;
        z-index: 666;
        float: left !important;
        bottom: 0 !important;
    }
}

@media only screen and (max-width: 1199px) {
    #sidebar.active {
        width: 80px;
        height: 100vh !important;
        position: fixed !important;
        /* overflow: visible !important; */
        top: 0 !important;
        z-index: 666;
        float: left !important;
        bottom: 0 !important;
    }
}

@media only screen and (max-width: 991px) {
    .custom-column {
        width: 25% !important;
    }

    #sidebar.active {
        width: 80px;
        height: 100vh !important;
        position: fixed !important;
        /* overflow: visible !important; */
        top: 0 !important;
        z-index: 666;
        float: left !important;
        bottom: 0 !important;
    }
}

@media only screen and (max-width: 767px) {
    .custom-column {
        width: 33% !important;
    }
}

@media only screen and (max-width: 575px) {
    .custom-column {
        width: 50% !important;
    }
}

@media only screen and (max-width: 420px) {
    .custom-column {
        width: 100% !important;
    }
}